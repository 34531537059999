import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createHead } from '@vueuse/head';
// import { AxiosError } from 'axios';
// import { fnReportError } from './api';
import tippy from 'tippy.js';
import i18n from './i18n';
import { createPinia } from 'pinia';
import { configureAxios } from './boot';

import '@unocss/reset/tailwind.css';
import 'uno.css';
import './index.scss';

import LogRocket from 'logrocket';
import * as Sentry from '@sentry/vue';
import.meta.env.VITE_LOGROCKET_APP_ID && LogRocket.init(import.meta.env.VITE_LOGROCKET_APP_ID);

const head = createHead({
    titleTemplate: '%s - House Care Admin',
});

const pinia = createPinia();
const app = createApp(App);

import.meta.env.VITE_SENTRY_DSN &&
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration({ maskAllText: false, maskAllInputs: false })],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/([a-z]+\.)*housecareonline\.in\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

app.use(pinia);
app.use(router);
app.use(head);
app.use(i18n);

// app.config.errorHandler = async (err, _vm) => {
//     if (!(err instanceof AxiosError)) {
//         const auth = useAuthStore();
//         console.error(err);
//         const body = (err as Error).stack || (err as Error).toString();
//         await fnReportError({
//             body,
//             location: window.location.href,
//             user: auth.user
//                 ? {
//                       id: auth.user.id,
//                       name: auth.user.name,
//                       roles: (auth.user.roles || []).map((x) => x.slug),
//                   }
//                 : {},
//         });
//     }
// };
app.directive('tooltip', (el: Element, binding) => {
    if ((el as any)._tippy) (el as any)._tippy.destroy();

    if (binding.value) el.setAttribute('title', binding.value);

    el.setAttribute('data-tippy-content', el.getAttribute('title') || '');
    tippy(el, {
        onShow(instance) {
            const ref = instance.reference;
            ref.setAttribute('data-title', ref.getAttribute('title') || '');
            ref.removeAttribute('title');
        },
        onHidden(instance) {
            const ref = instance.reference;
            ref.setAttribute('title', ref.getAttribute('data-title') || '');
            ref.removeAttribute('data-title');
        },
    });
});
app.directive('truncate', (el, binding) => {
    if (el._tippy) el._tippy.destroy();
    if (el.offsetWidth < el.scrollWidth) {
        el.setAttribute('data-tippy-content', binding.value || '');
        tippy(el);
    }
});

configureAxios();

app.mount('#app');

// Initiate service worker
if ('serviceWorker' in navigator && location.host.includes('housecareonline.in')) {
    // function skipWaiting(registration: ServiceWorkerRegistration) {
    //     setTimeout(() => {
    //         registration.waiting?.postMessage('SKIP_WAITING');
    //     }, 1000);
    // }

    window.addEventListener('load', async () => {
        try {
            let registration = await navigator.serviceWorker.register('/sw.js');

            if (registration.waiting) {
                // blockUi();
                // skipWaiting(registration);
            }

            registration.addEventListener('updatefound', () => {
                // blockUi();
                if (registration.installing) {
                    registration.installing.addEventListener('statechange', () => {
                        if (registration.waiting) {
                            if (navigator.serviceWorker.controller) {
                                // skipWaiting(registration);
                            }
                        }
                    });
                }
            });

            let refreshing = false;
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                console.log('[service worker] controller changed');
                if (!refreshing) {
                    window.location.reload();
                    refreshing = true;
                }
            });

            registration.update();
        } catch (e) {
            console.error(e);
        }
    });
}
