import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/addresses/:id/update',
        name: 'addresses.update',
        component: () => import('@/views/Address/Update.vue'),
        props: true,
    },
];

export default routes;
