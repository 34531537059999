import { SuccessResponse } from './types';
import { CategoryQuestionSchema, CategorySchema } from './schema';
import axios from 'axios';

export async function fnGetCategory(id: string) {
    const { data } = await axios.get<CategorySchema>(`/categories/${id}`);
    return data;
}

export async function fnGetAllCategories() {
    const { data } = await axios.get<CategorySchema[]>(`/categories`);
    return data;
}

export interface CategoryCreateRequest {
    title: string;
    description?: string | null;
    parent_id?: string | null;
}

export async function fnCreateCategory(payload: CategoryCreateRequest) {
    const { data } = await axios.post<CategorySchema>(`/categories`, payload);
    return data;
}

export async function fnUpdateCategory(id: string, payload: CategoryCreateRequest) {
    const { data } = await axios.put<CategorySchema>(`/categories/${id}`, payload);
    return data;
}

export async function fnDeleteCategory(id: string) {
    const { data } = await axios.delete<SuccessResponse>(`/categories/${id}`);
    return data.success;
}

export async function fnGetCategoryTotals() {
    const { data } = await axios.get<Record<string, number>>(`/categories/totals`);
    return data;
}

export async function fnGetCategoryQuestions(categoryId: string) {
    const { data } = await axios.get<CategoryQuestionSchema[]>(`/categories/${categoryId}/questions`);
    return data;
}
