<script setup lang="ts">
const auth = useAuthStore();
const dropdowns = reactive({
    customers: false,
});
const classes = {
    menu: 'text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2.5 pl-5 text-sm font-medium rounded-md decoration-none',
    active: 'bg-gray-900 !text-white',
};

function toggle(which: string) {
    dropdowns[which as keyof typeof dropdowns] = !dropdowns[which as keyof typeof dropdowns];
}

const version = import.meta.env.VITE_APP_VERSION;
</script>

<template>
    <div class="flex-1 flex flex-col min-h-0 bg-gray-800">
        <div class="flex items-center px-4 h-18 flex-shrink-0 bg-gray-900">
            <img src="/icon_96x96.png" alt="" class="h-10 w-auto" />
            <div class="ml-2">
                <div class="text-gray-50 font-bold text-lg">Admin</div>
                <div class="text-xs text-gray-400 font-mono">{{ version }}</div>
            </div>
        </div>
        <div class="flex-1 flex flex-col overflow-y-auto">
            <nav class="flex-1 px-2 py-4 space-y-1">
                <router-link :class="classes.menu" :active-class="classes.active" to="/dashboard">Dashboard</router-link>
                <router-link :class="classes.menu" :active-class="classes.active" to="/enquiries">Enquiries</router-link>
                <router-link :class="classes.menu" :active-class="classes.active" to="/customers">Customers</router-link>
                <router-link :class="classes.menu" :active-class="classes.active" to="/categories" v-if="auth.is('admin|dev')">Categories</router-link>
                <router-link :class="classes.menu" :active-class="classes.active" to="/partners">Partners</router-link>
                <router-link :class="classes.menu" :active-class="classes.active" to="/locations" v-if="auth.is('admin|dev')">Locations</router-link>
                <router-link :class="classes.menu" :active-class="classes.active" to="/staffs" v-if="auth.is('admin|dev')">Staffs</router-link>
                <router-link :class="classes.menu" :active-class="classes.active" to="/settings" v-if="auth.is('admin|dev')">Settings</router-link>
                <router-link :class="classes.menu" :active-class="classes.active" to="/reports" v-if="auth.is('admin|dev')">Reports</router-link>
                <router-link :class="classes.menu" :active-class="classes.active" to="/cache" v-if="auth.is('dev')">Cache</router-link>
                <router-link :class="classes.menu" :active-class="classes.active" to="/queue" v-if="auth.is('dev')">Queue</router-link>
                <router-link :class="classes.menu" :active-class="classes.active" to="/query" v-if="auth.is('dev')">Query</router-link>
                <!-- <router-link :class="classes.menu" :active-class="classes.active" to="/reports/work" v-if="is('admin|dev')">Reports</router-link> -->
            </nav>
        </div>
    </div>
</template>
