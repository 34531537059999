interface Toast {
    id: number;
    type: 'success' | 'error';
    title: string;
    description?: string | string[];
    timeoutId: any;
}

const toasts = ref<Toast[]>([]);
let count = 0;
const duration = 4000;
export function useToast() {
    function add(type: 'success' | 'error', title: string, description?: string | string[]) {
        const id = ++count;
        const timeoutId = setTimeout(() => {
            const index = toasts.value.findIndex((x) => x.id == id);
            toasts.value.splice(index, 1);
        }, duration);
        const toast: Toast = {
            id,
            type,
            title,
            description,
            timeoutId,
        };
        toasts.value.push(toast);
    }
    function success(title: string, description?: string) {
        add('success', title, description);
    }
    function error(title: string, description?: string | string[]) {
        add('error', title, description);
    }
    return {
        success,
        error,
        toasts,
    };
}
