import axios from 'axios';
import router from '@/router';
import bus from './bus';
import { useToast } from '@/composables';

const MAX_CONCURRENCY = 2;
let running = 0;

const http = axios.create({
    baseURL: `${import.meta.env.VITE_API_URL}/api`,
    timeout: 60 * 4 * 1000,
    headers: {
        // 'X-Requested-With': 'XMLHttpRequest',
    },
});

http.interceptors.request.use((config) => {
    return new Promise((resolve) => {
        const auth = useAuthStore();

        if (running < MAX_CONCURRENCY) {
            running++;
            document.body.classList.add('is-busy');
            if (!config.headers) config.headers = {};
            if (auth.token) {
                config.headers['Authorization'] = 'Bearer ' + auth.token;
            }
            return resolve(config);
        }

        function acquire() {
            if (running < MAX_CONCURRENCY) {
                running++;
                document.body.classList.add('is-busy');
                if (!config.headers) config.headers = {};
                if (auth.token) {
                    config.headers['Authorization'] = 'Bearer ' + auth.token;
                }
                bus.off('http:release', acquire);
                return resolve(config);
            }
        }
        bus.on('http:release', acquire);
    });
});

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value: any): boolean {
    return value && typeof value === 'string' && isoDateFormat.test(value);
}

function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== 'object') return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) body[key] = new Date(value);
        else if (typeof value === 'object') handleDates(value);
    }
}

http.interceptors.response.use(
    (response) => {
        document.body.classList.remove('is-busy');
        // handleDates(response.data);
        running = Math.max(0, running - 1);
        bus.emit('http:release');
        return response;
    },
    (error) => {
        document.body.classList.remove('is-busy');
        if (error.response?.status) {
            const auth = useAuthStore();
            const toast = useToast();
            let { status } = error.response;
            let message = '';
            switch (status) {
                case 401:
                    auth.signout();
                    router.replace({ path: '/login', query: { continue: window.location.pathname } });
                    break;
                default:
                    message = error.response?.data?.error || error.toString();
            }
            if (message) {
                toast.error(message);
            }
        }
        running = Math.max(0, running - 1);
        bus.emit('http:release');
        return Promise.reject(error);
    }
);

export default http;
