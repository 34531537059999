import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/settings',
        component: () => import('@/views/Setting/Layout.vue'),
        children: [
            {
                path: '',
                name: 'settings.general',
                component: () => import('@/views/Setting/General.vue'),
            },
            {
                path: 'payment',
                name: 'settings.payment',
                component: () => import('@/views/Setting/Payment.vue'),
            },
            {
                path: 'sms',
                name: 'settings.sms',
                component: () => import('@/views/Setting/Sms.vue'),
            },
            {
                path: 'whatsapp',
                name: 'settings.whatsapp',
                component: () => import('@/views/Setting/Whatsapp.vue'),
            },
            {
                path: 'integration',
                name: 'settings.integration',
                component: () => import('@/views/Setting/Integration.vue'),
            },
        ],
    },
];

export default routes;
