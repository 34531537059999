import { UserSchema } from './schema';
import axios from 'axios';

interface SignInRequest {
    username: string;
    password: string;
}

interface SignInResponse {
    user: UserSchema;
    token: string;
}

export async function fnSignIn(payload: FormData) {
    const { data } = await axios.post<SignInResponse>(`/signin`, payload);
    return data;
}

export async function fnSignOut() {
    await axios.post(`/signout`);
    return true;
}

export async function fnGetMe() {
    const { data } = await axios.get<UserSchema>(`/user`);
    return data;
}
