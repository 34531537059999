import { defineStore } from 'pinia';
import { useStorage, StorageSerializers } from '@vueuse/core';
import { UserSchema } from '@/api';

export const useAuthStore = defineStore('auth', () => {
    const user = useStorage<UserSchema | null>('user', null, localStorage, {
        mergeDefaults: true,
        serializer: StorageSerializers.object,
    });
    const token = useStorage<string>('token', '', localStorage, {
        mergeDefaults: true,
        serializer: StorageSerializers.string,
    });

    const isLoggedIn = computed(() => !!user.value && !!token.value);

    function signout() {
        user.value = null;
        token.value = null;
    }

    function is(roleStr: string) {
        if (!user.value) return false;

        const roles = Array.isArray(user.value?.roles) ? user.value.roles.map((x) => x.slug) : [];
        const requiredRoles = roleStr.split('|');
        for (const k of requiredRoles) {
            if (roles.indexOf(k) >= 0) {
                return true;
            }
        }
        return false;
    }

    return { user, token, signout, isLoggedIn, is };
});
