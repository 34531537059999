<script setup lang="ts">
import { useToast } from '@/composables';

const { toasts } = useToast();
</script>

<template>
    <div class="fixed top-4 right-4 left-4 z-40 lg:w-sm lg:left-auto toast-container">
        <div class="">
            <TransitionGroup
                class="flex flex-col-reverse gap-4"
                tag="div"
                enter-active-class="transition-300"
                leave-active-class="transition-300"
                enter-from-class="opacity-0 translate-x-16"
                leave-to-class="opacity-0 translate-x-16"
            >
                <div
                    v-for="toast in toasts"
                    class="shadow-lg rounded-lg ring-1 ring-opacity-25"
                    :class="{
                        'bg-red-50 ring-red-400': toast.type == 'error',
                        'bg-green-50 ring-green-500': toast.type == 'success',
                    }"
                    :key="`toast-${toast.id}`"
                >
                    <div class="p-4">
                        <div class="flex items-start">
                            <div class="flex-shrink-0">
                                <svg
                                    v-if="toast.type == 'error'"
                                    class="h-5 w-5 text-red-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                <svg
                                    v-if="toast.type == 'success'"
                                    class="h-5 w-5 text-green-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </div>
                            <div class="ml-3">
                                <p
                                    class="text-sm"
                                    :class="{
                                        'text-red-700': toast.type == 'error',
                                        'text-green-800': toast.type == 'success',
                                    }"
                                >
                                    {{ toast.title }}
                                </p>
                                <template v-if="toast.description?.length">
                                    <div class="mt-2 text-sm text-red-700" v-if="Array.isArray(toast.description)">
                                        <ul role="list" class="list-disc pl-5 space-y-1 errors">
                                            <li v-for="e in toast.description">{{ e }}</li>
                                        </ul>
                                    </div>
                                    <p
                                        v-else
                                        class="text-sm mt-1"
                                        :class="{
                                            'text-red-600': toast.type == 'error',
                                            'text-green-700': toast.type == 'success',
                                        }"
                                    >
                                        {{ toast.description }}
                                    </p>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </TransitionGroup>
        </div>
    </div>
</template>
