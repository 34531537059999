import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/reports',
        component: () => import('@/views/Report/Layout.vue'),
        children: [
            {
                path: '',
                name: 'report.completed-jobs',
                component: () => import('@/views/Report/CompletedJobs.vue'),
            },
        ],
    },
];

export default routes;
