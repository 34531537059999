<template>
    <div class="fixed inset-0 flex z-40 2xl:hidden" v-if="showNav">
        <div class="fixed inset-0 bg-gray-600 bg-opacity-75"></div>
        <div class="relative flex-1 flex flex-col max-w-xs w-full">
            <MainNav />
            <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                    type="button"
                    @click.prevent="showNav = false"
                    class="ml-1 border-0 bg-transparent flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white cursor-pointer"
                >
                    <span class="sr-only">Close sidebar</span>
                    <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
            </div>
        </div>
        <div class="flex-shrink-0 w-14"></div>
    </div>
    <div class="hidden 2xl:flex 2xl:w-64 2xl:flex-col 2xl:fixed 2xl:inset-y-0">
        <MainNav />
    </div>
    <div class="2xl:pl-64 flex flex-col">
        <div class="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
                type="button"
                @click.prevent="showNav = true"
                class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 2xl:hidden"
            >
                <span class="sr-only">Open sidebar</span>
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7"></path>
                </svg>
            </button>
            <div class="flex-1 px-4 flex justify-between">
                <div class="flex-1 flex"></div>
                <div class="ml-4 flex items-center lg:ml-6">
                    <router-link :to="`/users/${auth.user?.id}/update`" class="decoration-none flex items-center text-gray-500 text-sm hover:text-gray-700">
                        <Icon icon="carbon:user-filled" :inline="true" class="text-base" />
                        <span class="is-block ml-1">{{ auth.user?.name }}</span>
                    </router-link>

                    <button
                        type="button"
                        @click.prevent="logout"
                        class="bg-white border-0 p-2 ml-2 cursor-pointer text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <span class="sr-only">Logout</span>
                        <Icon icon="fontisto:power" :inline="true" />
                    </button>
                </div>
            </div>
        </div>
        <main class="flex-1">
            <div class="p-4 lg:p-6">
                <slot />
            </div>
        </main>
    </div>
</template>
<script setup lang="ts">
import { Icon } from '@iconify/vue';
import { fnSignOut } from '@/api';
import { useCategory, useConfig, useLocation } from '@/composables';
const router = useRouter();
const route = useRoute();
const auth = useAuthStore();

const { fetch: fetchCategories } = useCategory();
const { fetch: fetchLocations } = useLocation();
const { refresh } = useConfig();

const showNav = ref(false);

watch(
    () => route.path,
    () => {
        showNav.value = false;
    }
);

const logout = async () => {
    try {
        await fnSignOut();
        auth.signout();
        router.replace({ name: 'login' });
    } catch (err) {
        console.error(err);
    }
};

onMounted(() => {
    refresh();
    fetchCategories();
    fetchLocations();
});
</script>
