import { computed, ref } from 'vue';
import { LocationSchema } from '@/api';
import axios from 'axios';

const data = ref<LocationSchema[]>([]);

export function useLocation() {
    const fetch = async () => {
        const response = await axios.get(`/locations`);
        data.value = response.data;
    };

    const tree = (items: LocationSchema[] = []): LocationSchema[] => {
        const root = [];
        const lookup: any = {};
        for (const item of items) {
            const id = item.id;
            const parentId = item.parent_id;

            if (!lookup[id]) lookup[id] = { ['children']: [] };

            lookup[id] = { ...item, ['children']: lookup[id]['children'] };

            const TreeItem = lookup[id];

            if (!parentId) {
                root.push(TreeItem);
            } else {
                if (!lookup[parentId]) lookup[parentId] = { ['children']: [] };

                lookup[parentId]['children'].push(TreeItem);
            }
        }
        return root;
    };

    const locations = computed(() => {
        return tree(clone(data.value || []));
    });

    const states = computed(() => {
        const items = (data.value || []).filter((x) => x.type == 'state');
        return tree(clone(items));
    });

    const districts = computed(() => {
        const items = (data.value || []).filter((x) => ['state', 'district'].includes(x.type));
        return tree(clone(items));
    });

    const areas = computed(() => {
        const items = (data.value || []).filter((x) => ['state', 'district', 'area'].includes(x.type));
        return tree(clone(items));
    });

    const pincodes = computed(() => {
        const pincodes = (data.value || []).filter((x) => x.type == 'pincode');
        const areas = (data.value || []).filter((x) => x.type == 'area');
        const districts = (data.value || []).filter((x) => x.type == 'district');
        const states = (data.value || []).filter((x) => x.type == 'state');
        return pincodes.map((p) => {
            let area = areas.find((a) => a.id == p.parent_id);
            let district = area ? districts.find((d) => d.id == area?.parent_id) : null;
            let state = district ? states.find((s) => s.id == district?.parent_id) : null;
            return {
                pincode: p.title,
                area: area?.title,
                district: district?.title,
                state: state?.title,
            };
        });
    });

    const duplicates = computed(() => {
        const items = (data.value || []).filter((x) => ['pincode'].includes(x.type));
        const pincodes: LocationSchema[] = clone(items);
        const titles: string[] = pincodes.map((x) => x.title);
        const set = new Set(titles);
        const d = titles.filter((t) => {
            if (set.has(t)) {
                set.delete(t);
            } else {
                return t;
            }
        });
        return d.sort();
    });

    const getLocation = (id: string) => {
        return data.value?.find((x) => x.id == id);
    };

    return {
        locations,
        getLocation,
        states,
        districts,
        areas,
        pincodes,
        fetch,
        duplicates,
    };
}
