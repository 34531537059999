import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/staffs/create',
        name: 'staffs.create',
        component: () => import('@/views/Staff/Create.vue'),
        meta: { roles: 'admin|dev' },
    },
    {
        path: '/staffs',
        name: 'staffs.index',
        component: () => import('@/views/Staff/Index.vue'),
        meta: { roles: 'admin|dev' },
    },
];

export default routes;
