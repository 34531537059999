import axios from 'axios';
import { useToast } from './composables';
import router from './router';

export function configureAxios() {
    axios.interceptors.request.use(function (config) {
        config.baseURL = `${import.meta.env.VITE_API_URL}/api`;
        if (!config.headers) config.headers = {};

        const token = localStorage.getItem('token');
        if (token) config.headers['Authorization'] = 'Bearer ' + token;

        return config;
    });

    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            const toast = useToast();
            if (error.response) {
                const status = error.response.status;
                switch (status) {
                    case 401:
                        const auth = useAuthStore();
                        toast.error('Your session is inactive. Please login again.');
                        auth?.signout();
                        router?.replace({ path: '/login', query: { continue: window.location.pathname } });
                        break;
                    case 422:
                        const errs: string[] = [];
                        for (const err of error.response.data.errors) {
                            // if (!errors.value[err.field]) errors.value[err.field] = err.message;
                            errs.push(err.message);
                        }
                        toast.error('Please review the following and try again', errs);
                        break;
                    case 500:
                        toast.error('Something went wrong.', "Don't worry. We are looking into it right now.");
                        break;
                    case 404:
                        toast.error('Resource not found.', 'The resource you are looking for is not found here.');
                        break;
                    case 403:
                        toast.error('Access denied', 'You do not have proper permission to access this resource.');
                        break;
                    case 429:
                        toast.error('Too many attempts', 'Please try again after some time');
                        break;
                    default:
                        toast.error(error.response.data?.error || error.response.data?.message || error.message || error.toString());
                }
            } else if (error.request) {
                toast.error('Could not connect to server', error.message || error.toString());
            } else {
                toast.error('Failed to initiate connection', error.message || error.toString());
            }
            console.log(error.config);
            return Promise.reject(error);
        }
    );
}
