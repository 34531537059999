interface Modal {
    title: string;
    description?: string;
    confirm: () => void;
    cancel: () => void;
}

const modal = ref<Modal>();

export function useModal() {
    function confirm(title: string, description?: string) {
        return new Promise<boolean>((resolve) => {
            modal.value = {
                title,
                description,
                confirm() {
                    resolve(true);
                    modal.value = undefined;
                },
                cancel() {
                    resolve(false);
                    modal.value = undefined;
                },
            };
        });
    }
    return { confirm, modal: readonly(modal) };
}
