import { cloneDeep } from 'lodash';

export function isMobile() {
    const ua = /Mobi/i.test(window.navigator.userAgent);
    const match = window.matchMedia || (window as any).msMatchMedia;
    if (match) {
        let coarse = match('(pointer:coarse)');
        let fine = match('(any-pointer:fine)');
        return ua && coarse.matches && !fine.matches && window.innerWidth <= 1024;
    }
    return false;
}

export function sleep(ms: number): Promise<any> {
    return new Promise((res) => setTimeout(res, ms));
}

export function clone(v: any): any {
    return cloneDeep(v);
}
