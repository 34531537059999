import { marked } from 'marked';

export function capitalize(str: string): string {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export function md2html(str: string): string {
    return marked.parse(str);
}

export function nl2br(str: string): string {
    return str.replace(/\n/g, '<br />');
}
