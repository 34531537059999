<script setup lang="ts">
import { layouts } from './layouts';
import type { Component } from 'vue';

const layout = shallowRef<Component | string>('div');
const router = useRouter();
router.afterEach((to) => {
    const layoutName = capitalize((to.meta.layout as string) || 'panel') + 'Layout';
    layout.value = layouts[layoutName as keyof typeof layouts] || 'div';
});
const isOnline = ref(false);
</script>

<template>
    <component :is="layout || 'div'">
        <router-view />
    </component>

    <ToastContainer />
    <ModalContainer />
</template>
