import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/categories',
        name: 'categories.index',
        component: () => import('@/views/Category/Index.vue'),
        meta: { roles: 'admin|dev' },
    },
    {
        path: '/categories/create',
        name: 'categories.create',
        component: () => import('@/views/Category/Create.vue'),
        meta: { roles: 'admin|dev' },
    },
    {
        path: '/categories/:id/update',
        name: 'categories.update',
        component: () => import('@/views/Category/Update.vue'),
        meta: { roles: 'admin|dev' },
    },
    {
        path: '/categories/:id/questions',
        name: 'categories.questions',
        component: () => import('@/views/Category/Question.vue'),
        meta: { roles: 'admin|dev' },
    },
];

export default routes;
