import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/locations',
        name: 'locations.index',
        component: () => import('@/views/Location/Index.vue'),
        meta: { roles: 'admin|dev' },
    },
    {
        path: '/locations/create/:type',
        name: 'locations.create',
        component: () => import('@/views/Location/Create.vue'),
        meta: { roles: 'admin|dev' },
    },
    {
        path: '/locations/:id/:type',
        name: 'locations.update',
        component: () => import('@/views/Location/Update.vue'),
        meta: { roles: 'admin|dev' },
    },
];

export default routes;
