import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/customers',
        name: 'customers.index',
        component: () => import('@/views/Customer/Index.vue'),
    },
    {
        path: '/customers/by-job',
        name: 'customers.by-job',
        component: () => import('@/views/Customer/ByJob.vue'),
        meta: { roles: 'admin|dev' },
    },
    {
        path: '/customers/duplicate-phones',
        name: 'customers.duplicate-phones',
        component: () => import('@/views/Customer/DuplicatePhone.vue'),
    },
    {
        path: '/customers/invalid-names',
        name: 'customers.invalid-names',
        component: () => import('@/views/Customer/InvalidName.vue'),
    },
    {
        path: '/customers/:id',
        name: 'customers.show',
        component: () => import('@/views/Customer/Show.vue'),
        props: true,
    },
];

export default routes;
