import { useHttp } from './use-http';
import { useToast } from './use-toast';

export interface Configuration {
    'sms.login.message': string;
    'sms.login.header': string;
    'sms.login.templateId': string;
    'sms.login.entityId': string;
    'sms.login.provider': string;
    'sms.login.enable': boolean;
    'sms.complete.message': string;
    'sms.complete.header': string;
    'sms.complete.templateId': string;
    'sms.complete.entityId': string;
    'sms.complete.provider': string;
    'sms.complete.enable': boolean;
    'sms.payment.message': string;
    'sms.payment.header': string;
    'sms.payment.templateId': string;
    'sms.payment.entityId': string;
    'sms.payment.provider': string;
    'sms.payment.enable': boolean;
    'demo.key': string;
    'cache.prefix': string;
    'payment.update': boolean;
    'payment.delete': boolean;
    'assignment.uncomplete': boolean;
    'whatsapp.enable': boolean;
    'whatsapp.receipt.message': string;
    'whatsapp.receipt.attach': boolean;
    'integration.whatsapp.accessToken': string;
    'integration.whatsapp.instanceId': string;
    'integration.whatsapp.sendendpoint': string;
    'integration.switchy.authcode': string;
    'integration.callerdesk.authcode': string;
    'integration.myctrlbox.username': string;
    'integration.myctrlbox.apikey': string;
    'integration.nimbusit.username': string;
    'integration.nimbusit.password': string;
    'notification.numbers': string;
}

const config = reactive<Configuration>({
    'sms.login.message': '',
    'sms.login.header': '',
    'sms.login.templateId': '',
    'sms.login.entityId': '',
    'sms.login.provider': '',
    'sms.login.enable': false,
    'sms.complete.message': '',
    'sms.complete.header': '',
    'sms.complete.templateId': '',
    'sms.complete.entityId': '',
    'sms.complete.provider': '',
    'sms.complete.enable': false,
    'sms.payment.message': '',
    'sms.payment.header': '',
    'sms.payment.templateId': '',
    'sms.payment.entityId': '',
    'sms.payment.provider': '',
    'sms.payment.enable': false,
    'demo.key': '',
    'cache.prefix': '',
    'payment.update': false,
    'payment.delete': false,
    'assignment.uncomplete': false,
    'whatsapp.enable': false,
    'whatsapp.receipt.message': '',
    'whatsapp.receipt.attach': false,
    'integration.whatsapp.accessToken': '',
    'integration.whatsapp.instanceId': '',
    'integration.whatsapp.sendendpoint': '',
    'integration.switchy.authcode': '',
    'integration.callerdesk.authcode': '',
    'integration.myctrlbox.username': '',
    'integration.myctrlbox.apikey': '',
    'integration.nimbusit.username': '',
    'integration.nimbusit.password': '',
    'notification.numbers': '',
});

const { exec } = useHttp<Configuration>();
const toast = useToast();

export function useConfig() {
    async function set<K extends keyof Configuration>(key: K, value: Configuration[K]) {
        await exec({ url: '/admin/config', method: 'post', data: { key, value } });
        toast.success('Setting saved.');
        refresh();
    }
    async function refresh() {
        const result = await exec({ url: '/admin/config', method: 'get' });
        const keys = Object.keys(result);
        for (const k of keys) {
            const kk = k as keyof Configuration;
            (config as any)[kk] = result[kk];
        }
    }
    function computedConfig<K extends keyof Configuration>(key: K) {
        return computed({
            get: () => config[key],
            set: (v) => set(key, v),
        });
    }
    return { config, computedConfig, refresh };
}
