import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/enquiries/create',
        name: 'enquiries.create',
        component: () => import('@/views/Enquiry/Create/Index.vue'),
    },
    {
        path: '/enquiries/:id',
        name: 'enquiries.show',
        component: () => import('@/views/Enquiry/Show.vue'),
    },
    {
        path: '/enquiries',
        name: 'enquiries.index',
        component: () => import('@/views/Enquiry/Index.vue'),
    },
];

export default routes;
