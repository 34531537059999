import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/partners/create',
        name: 'partners.create',
        component: () => import('@/views/Partner/Create.vue'),
    },
    {
        path: '/partners/:id',
        component: () => import('@/views/Partner/Layout.vue'),
        props: true,
        children: [
            {
                path: 'address',
                name: 'partners.address',
                component: () => import('@/views/Partner/Address.vue'),
                props: true,
            },
            {
                path: 'service',
                name: 'partners.service',
                component: () => import('@/views/Partner/Service.vue'),
                props: true,
            },
            {
                path: 'jobs',
                name: 'partners.jobs',
                component: () => import('@/views/Partner/Job.vue'),
                props: true,
            },
            {
                path: 'account',
                name: 'partners.account',
                component: () => import('@/views/Partner/Account.vue'),
                props: true,
            },
            {
                path: '',
                name: 'partners.show',
                component: () => import('@/views/Partner/Show.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/partners',
        name: 'partners.index',
        component: () => import('@/views/Partner/Index.vue'),
    },
];

export default routes;
